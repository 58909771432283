<template>
  <!-- Color Styles-->
  <div v-if="!fonts">
    <!-- Custom elements params-->
    <div v-if="hasColorsVariables" class="pa-4">

      <!-- Information and reset button -->

      <h5 v-if="element.config && Object.keys(element.config.color_styles).length === 0" :style="`color : ${wsDARKER}`" class="font-weight-regular mb-5">
        {{ $t('ElementColorStyleDescription') }}
        <span @click="openColorSettings" class="font-weight-bold pointer linkHover" :style="`color : ${wsATTENTION}`"> {{ $t('ColorSettings') }}</span>
      </h5>
      <h5 v-else class="mb-5 pointer linkHover" @click="resetToConfigColors" :style="`color : ${wsACCENT}`"> {{ $t('ResetFontsToConfigValues') }}</h5>


      <div v-for="(params , group , index) in colorsVariables" :key="group">
        <h4 :style="`color : ${wsDARKER}`" :class="[{'mt-3' : index !== 0}]">{{ $t(GET_COLORS_PARAMS_LOCALES(group)) }}</h4>
        <color-param-picker
            :label="GET_COLORS_PARAMS_LOCALES(group , param)"
            @input="updateBlockColorParam(param , $event)"
            v-for="(param , i) in params" :key="group + i"
            :value = "element.config.color_styles[param] || CONFIG_PARAM('styles' , blockStyle , param )"
            @expand="expandedElement = $event"
            :expanded-element="expandedElement"
        />
      </div>


    </div>
  </div>
  <!-- Color Styles-->
  <div v-else class="pa-4">

    <template v-if="hasFontsVariables">

      <!-- Information and reset button -->
      <h5 v-if="Object.keys(element.config.fonts_styles).length === 0" :style="`color : ${wsDARKER}`" class="font-weight-regular mb-5">
        {{ $t('ElementFontsStyleDescription') }}
        <span @click="openFontsSettings" class="font-weight-bold pointer linkHover" :style="`color : ${wsATTENTION}`"> {{ $t('FontsSettings') }}</span>
      </h5>
      <h5 v-else class="font-weight-bold mb-5 pointer linkHover" @click="resetToConfigFonts" :style="`color : ${wsATTENTION}`"> {{ $t('ResetFontsToConfigValues') }}</h5>

      <!-- Fonts Params  -->
      <div v-for="(params , group , index) in fontsVariables" :key="group">

        <h4 :style="`color : ${wsDARKER}`" :class="[{'mt-3' : index !== 0}]">{{ GET_FONT_PARAM_NAME(group) }}</h4>

        <template  v-for="(param , i) in params" >
          <font-style-param-picker
              @input="changeFontParam(group , param , $event )"
              :key="group + i + 'font' "
              :label="GET_FONT_PARAM_NAME( group , param )"
              :value="element.config.fonts_styles[group + '__' + param ] || CONFIG_PARAM('fonts','elements',group, param ) "
          />
        </template>

      </div>

    </template>


  </div>



</template>

<script>
import colorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker";
import fontStyleParamPicker from "@/components/AvalonEditor/UI/fontStyleParamPicker";

export default {
  name: "elementStyleSettings",
  components: {
    colorParamPicker,
    fontStyleParamPicker
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    element : {
      type : Object,
      default() { return {} }
    },
    fonts : {
      type : Boolean,
      default : false
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      expandedElement : null
    }
  },
  computed : {
    hasColorsVariables() {

      if ( !this.element.type ) {
        return false
      }

      if ( !this.$store.state.avalon.elementsConfig[this.element.type] ) {
        return false
      }

      if ( !this.$store.state.avalon.elementsConfig[this.element.type]['variables'] ) {
        return false
      }

      return !!this.$store.state.avalon.elementsConfig[this.element.type]['variables']['colors']

    },
    hasFontsVariables() {

      if ( !this.element.type ) {
        return false
      }

      if ( !this.$store.state.avalon.elementsConfig[this.element.type] ) {
        return false
      }
      if ( !this.$store.state.avalon.elementsConfig[this.element.type]['variables'] ) {
        return false
      }

      return !!this.$store.state.avalon.elementsConfig[this.element.type]['variables']['fonts']

    },
    colorsVariables() {
      return this.$store.state.avalon.elementsConfig[this.element.type]['variables']['colors']
    },
    fontsVariables() {
      return this.$store.state.avalon.elementsConfig[this.element.type]['variables']['fonts']
    }
  },
  methods : {

    openFontsSettings() {
      this.$store.state.avalon.menuNavigationElement = 'other'
      this.$store.state.avalon.menuNavigation = 'fonts_edit_element'
      this.$store.state.avalon.menuNavigationReturn = 'fonts'
      this.$store.state.avalon.sideMenu = true
      this.$store.state.avalon.menuOnlyStylesAndFonts = true

    },
    openColorSettings() {
      this.$store.state.avalon.menuNavigationStyleTheme = this.blockStyle || 'light_1'
      this.$store.state.avalon.menuNavigation = 'styles_theme'
      this.$store.state.avalon.menuNavigationReturn = 'styles'
      this.$store.state.avalon.sideMenu = true
      this.$store.state.avalon.menuOnlyStylesAndFonts = true
    },
    resetToConfigColors() {
      this.element.config.color_styles = {}
      this.updateSettings()
    },
    resetToConfigFonts() {
      this.element.config.fonts_styles = {}
      this.updateSettings()
    },
    changeFontParam(element, param , value ) {
      this.element.config.fonts_styles[element + '__' + param ] = value
      this.updateSettings()
    },
    updateBlockColorParam(param , value ) {
      this.element.config.color_styles[param] = value
      this.updateSettings()
    },
    updateSettings() {
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    }
  },
  beforeMount() {
    if ( !this.element.config.color_styles ) {
      this.element.config.color_styles = {}
      this.updateSettings()
    }

    if ( Array.isArray( this.element.config.color_styles) ) {
      this.element.config.color_styles = {}
      this.updateSettings()
    }

    if ( !this.element.config.fonts_styles ) {
      this.element.config.fonts_styles = {}
      this.updateSettings()
    }


    if ( Array.isArray( this.element.config.fonts_styles) ) {
      this.element.config.fonts_styles = {}
      this.updateSettings()
    }


  }
}
</script>

<style scoped>

</style>