<template>


  <div>

    <!--  Content Parameters-->
    <div v-if="!isStyle">
      <div>

        <image-param-picker
            @input="updateImage"
            v-model="element.text"
        />

        <ws-text-field
            class="mt-7"
            @change="$emit('input' , element.config )"
            v-model="element.config.alt"
            :label="$t('ImageAlternativeText')"
            :hide-details="false"
            :placeholder="$t('EnterImageAlternativeText')"
        />

        <ws-text-field
            @change="$emit('input' , element.config )"
            v-model="element.config.link"
            :label="$t('Link')"
            placeholder="https://exmaple.com"
            clearable
        />
        <ws-check-box
            class="ml-n2 mt-5"
            @input="$emit('input' , element.config )"
            v-model="element.config.link_blank"
            :small="false"
        >
          <h5 :style="`color : #4F5A6E; font-size: 13px; font-weight: 500 `" >
            {{ $t('OpenInNewWindow') }}
          </h5>
        </ws-check-box>


      </div>

    </div>
    <!--  Style Parameters-->
    <div v-else>

      <ws-accordion
          :items="navigationSelect"
      >

        <template #item.general >

          <div class="pa-5">
            <a-parameter-switch
                @input="$emit('input' , COPY(element.config) )"
                v-model="element.config.contain"
                label="avalon.image.contain"
            />

              <ws-button-group
                  v-if="element.config.contain"
                  @input="$emit('input' , COPY(element.config) )"
                  v-model="element.config.contain_full_width"
                  :items="containSelect"
                  class="mb-2 mt-5"
              />


          </div>

          <v-divider :style="`border-color : ${wsBACKGROUND}`" />


            <v-expand-transition>
              <div >
                <h5  :style="`color : ${wsDARKLIGHT}`"
                     class="pt-3 px-5"
                > {{ $t('ImageDisposition') }}</h5>
                <div v-if="!MOBILE_VIEW" class="px-5 pt-2">
                  <a-parameter-slider
                      @change="$emit('input' , element.config )"
                      v-model="element.config.image_position_x"
                      :default="50"
                      label="avalon.image.horizontal_align"
                      min="1"
                      max="100"
                  />
                  <a-parameter-slider
                      class="mt-3 pb-6"
                      @change="$emit('input' , element.config )"
                      v-model="element.config.image_position_y"
                      :default="50"
                      label="avalon.image.vertical_align"
                      min="1"
                      max="100"
                  />
                </div>
                <div v-if="MOBILE_VIEW" class="px-5 pt-2" >
                  <a-parameter-slider
                      @change="$emit('input' , element.config )"
                      v-model="element.config.image_position_sm_x"
                      :default="50"
                      label="avalon.image.horizontal_align"
                      min="1"
                      max="100"
                  />
                  <a-parameter-slider
                      class="mt-3 pb-6"
                      @change="$emit('input' , element.config )"
                      v-model="element.config.image_position_sm_y"
                      :default="50"
                      label="avalon.image.vertical_align"
                      min="1"
                      max="100"
                  />

                </div>

                <v-divider :style="`border-color : ${wsBACKGROUND}`" />

              </div>
            </v-expand-transition>





          <a-parameter-slider
              @change="$emit('input' , element.config )"
              class=" px-5 pb-6"
              v-model="element.config.rounded"
              :default="0"
              label="Rounded"
              min="0"
              max="50"
          />

        </template>

        <template #item.mask>
          <v-row no-gutters class="pa-3 px-3" >
            <v-col
                v-for="mask in CLIPPING_MASKS_ARRAY" :key="mask"
                cols="3"
            >
              <div class="d-flex justify-center">
                <div class="py-2">
                  <v-sheet @click="changeMask(mask)" v-ripple class="pointer" height="60" :color="element.config.mask === mask ? wsATTENTION : wsBACKGROUND" :style="GET_CLIPPING_MASK(mask , true)"  >
                  </v-sheet>
                </div>
              </div>


            </v-col>
          </v-row>

          <v-divider
              :style="`border-color : ${wsBACKGROUND};`"
              style="border-width: 1px"
              class="mt-4"
          />

          <a-parameter-switch
              @input=" element.config.mask_resize = !$event; $emit('input' , this.COPY(element.config))"
              :value="!element.config.mask_resize"
              label="format.resize"
              class="mt-2 px-3 pb-4 pt-2"
          />


        </template>

        <template #item.color>
          <element-style-settings
              @input="updateSettings"
              v-model="element.config"
              :element="element"
              :block-style="blockStyle"
          />
        </template>

      </ws-accordion>

    </div>

  </div>

</template>

<script>

import elementStyleSettings from "@/components/AvalonEditor/Dialogs/elements/elementStyleSettings";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
export default {
  name: "avalonTextSettings",
  components : {
    imageParamPicker,
    elementStyleSettings
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      config : {},
      displayLibrary : false,
    }
  },
  computed : {
    containSelect() {
      return [
        { text : this.$t('ContainInside') , value : false },
        { text : this.$t('ContainWide') , value : true },
      ]
    },
    navigationSelect() {
      return [
        { name : this.$t('GeneralSettings') , value : 'general' , expanded : true} ,
        { name : this.$t('ImageMask') , value : 'mask' },
        { name : this.$t('Color') , value : 'color' }
      ]
    },
    addImageActionsSelect() {
      return [
        { text : this.$t('Upload') , value : 'upload' , icon : 'mdi-upload' },
        { text : this.$t('BrowseCollection') , value : 'browse' , icon : 'mdi-grid' }
      ]
    }
  },
  methods : {
    updateSettings() {
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
    uploadImage($event) {
      if ( !$event.url ) {
        return
      }
      this.element.text = $event.url
      this.$emit('update-element' , this.element)
    },
    updateImage() {
      this.$emit('update-element' , this.COPY(this.element))
    },
    addImageAction(value , selectFunction) {
      if ( value === 'upload') {
        selectFunction()
        return
      }

      this.displayLibrary = true
    },

    changeMask(mask) {
      this.element.config.mask = mask
      this.element = this.COPY(this.element)
      this.$emit('input' , this.element.config)
    },
    editLineValign(val) {
      this.notify(val)
    }
  },
  mounted() {
    this.config = this.COPY(this.value)
  }

}
</script>

<style scoped>

</style>